
import { defineComponent, reactive } from 'vue';
import YImage from '@/components/modules/image/index.vue';
import { updateUser } from '@/api/user';
import { useTable } from './setup';
import { useModel } from '@/hooks/use-modal';
import { awaitWrap } from '@/util/index';
import { Modal } from 'ant-design-vue';

export default defineComponent({
  components: { YImage, AModal: Modal },
  setup() {
    const { tableColumns, searchRef, pagination, tableSource, tableLoading, getList, handlePageChange, handleReset } = useTable();

    const editDistributionRatioInfo: any = reactive({
      ratio: '',
      id: '',
    });
    const { visible, confirmLoading, handleOk } = useModel({
      async handleOk() {
        const { code } = await awaitWrap(updateUser(editDistributionRatioInfo));
        confirmLoading.value = false;

        if (code === 200) {
          visible.value = false;
          getList();
        }
      },
    });

    function handleShowRatioModel(el: any) {
      editDistributionRatioInfo.ratio = el.DistributionRatio;
      editDistributionRatioInfo.id = el.Id;
      visible.value = true;
    }

    return {
      tableColumns,
      searchRef,
      pagination,
      tableSource,
      tableLoading,
      getList,
      handlePageChange,
      handleReset,
      visible,
      editDistributionRatioInfo,
      confirmLoading,
      handleOk,
      handleShowRatioModel,
    };
  },
});
