
export const skillTableModel = [
  { title: '用户头像', key: 'npc', slots: { customRender: 'npc' }},
  { title: '昵称', dataIndex: 'NickName', key: 'NickName'},
  { title: '手机号', dataIndex: 'Mobile', key: 'Mobile'},
  { title: '性别',dataIndex: 'GenderStr', key: 'category' },
  { title: '钱包',dataIndex: 'WalletBalance', key: 'walletBalance', slots: { customRender: 'walletBalance' } },
  { title: '提成',dataIndex: 'DistributionRatio', key: 'distributionRatio', slots: { customRender: 'distributionRatio' } },
  { title: '用户属性', key: 'IsNpc', slots: { customRender: 'IsNpc' } },
  // { title: '操作', key: 'action', slots: { customRender: 'action' }, fixed: 'right' },
];

export const skillSearch = {
  IsNpc: '',
  Mobile: '',
  NickName: '',
  PageSize: 10,
  PageIndex: 1,
};
