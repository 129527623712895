import { reactive, ref } from 'vue';
import { awaitWrap } from '@/util/index';
import _ from 'lodash';

export function useModel(option: any): any {
  const visible = ref(false);
  const confirmLoading = ref(false);
  function handleOk() {
    confirmLoading.value = true;
    option.handleOk && option.handleOk.call();
  }
  return { visible, confirmLoading, handleOk };
}
