import { reactive, computed, ref } from 'vue';
import _ from 'lodash';
import { skillTableModel, skillSearch } from '@/data/user';
import { manageList } from '@/api/user';
import { awaitWrap } from '@/util/index';
import { useInitRoute } from '@/util/router';
export function useTable(): any {
  const tableColumns = skillTableModel;

  const { route } = useInitRoute();

  const searchRef = reactive(_.clone(skillSearch));
  searchRef.NickName = route.query.NickName || '';

  const pagination = reactive({
    current: 1,
    total: 10,
  });

  const tableSource = ref([]);
  const tableLoading = ref(false);

  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(manageList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }
  getList();

  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  function handleReset() {
    _.assignIn(searchRef, skillSearch);
  }



  return {
    tableColumns,
    searchRef,
    pagination,
    tableSource,
    tableLoading,
    getList,
    handlePageChange,
    handleReset,
  };
}
